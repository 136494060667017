/* eslint-disable @typescript-eslint/no-explicit-any */
import { buildQueryParamString } from '@cw/contexts/HttpClient.context';
import { ICompetitionSection, COMPETITION_SECTION_TYPE } from '@cw/models/competitionSection';
import { TOnTheDayLinkType } from '@cw/models/onTheDay';
import { TDataDisplayType } from '@cw/models/shared';
import { formatCurrency, formatNumber } from './numberUtils';
import { formatDate } from './dateUtils';
import { ICompetitorProfile } from '@cw/models/competitorProfile';
import { IPublishedCompetitionCompetitorSectionPartnerExpanded } from '@cw/models/competition';

export const camelCaseToUserText = (input: string): string => (
  `${input[0].toUpperCase()}${input.replace(/([A-Z](?=[a-z]+)|[A-Z]+(?![a-z]))/g, ' $1').slice(1)}`
);

export const splitByCapitalLetter = (input: string) => !input ? '' : input.match(/[A-Z][a-z]+/g)?.join(' ');

export const cleanupPhoneNumber = (number?: string | null): string => {
  if (!number) {
    return '';
  }

  // eslint-disable-next-line no-useless-escape
  return number.replace(/[ \(\)\-]/g, '');
}

export const formatRoute = (route: string, routeParams: Record<string, string>, queryParams?: Record<string, string>): string => {
  let formattedRoute = `${route}`;

  Object.keys(routeParams).forEach((key) => {
    formattedRoute = formattedRoute.replace(`:${key}`, encodeURIComponent(routeParams[key]));
  });

  let queryParamsString = '';
  if (queryParams) {
    queryParamsString = buildQueryParamString(queryParams);
  }

  return `${formattedRoute}${queryParamsString}`;
}

export const isValidUrl = (url: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  return /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(url);
}

export const formatSectionType = (sectionType?: COMPETITION_SECTION_TYPE | null): string => {
  switch (sectionType) {
    case COMPETITION_SECTION_TYPE.DuoTrio: return 'Duo / Trio';
    case COMPETITION_SECTION_TYPE.Troupe: return 'Troupes';
    default: return sectionType ?? '';
  }
}

export const formatSectionNumber = (section?: ICompetitionSection | null): string => {
  if (!section) return '';
  
  return `Section ${section.sectionNumber}`;
}

export const formatSectionName = (section?: ICompetitionSection | null): string => {
  if (!section) return '';
  
  return `${formatSectionNumber(section)}: ${section.name}`;
}

export const formatSectionAgeRange = (section?: ICompetitionSection | null): string => {
  if (!section) return '';

  if (section.minAge === section.maxAge) {
    return `${section.minAge} ${plurify('year', section.maxAge)}`;
  } else {
    return `${section.minAge} - ${section.maxAge} years`;
  }
}

export const formatSectionFullName = (section?: ICompetitionSection | null, excludeSectionNumber?: boolean): string => {
  if (!section) return '';

  if (excludeSectionNumber) {
    return section.name;
  }
  return formatSectionName(section);
}

export const formatOnTheDayLinkTypeName = (linkType: TOnTheDayLinkType): string => {
  switch (linkType) {
    case 'MusicPlayer': return 'Live Music Player';
    case 'StageMonitor': return 'Stage Lineup Display';
    case 'Adjudicator': return 'Adjudicator / Tabulator';
    default: return '';
  }
}

const convertErrorObject = (error: Error) : any => {
  const errorDetails = {
    message: error.message,
    name: error.name,
    stack: error.stack,
    cause: error.cause instanceof Error ? convertErrorObject(error.cause) : error.cause,
    additionalInfo: {} as Record<string, any>,
  };

  // Capture any additional enumerable properties
  for (const key of Object.keys(error)) {
    if (!(key in errorDetails)) {
      errorDetails.additionalInfo[key] = (error as any)[key];
    }
  }

  return errorDetails;
}

export const convertToObject = (obj: any): any => {
  if (!!obj && Array.isArray(obj)) {
    const convertedArray: any[] = [];
    for (const item of obj) {
      convertedArray.push(convertToObject(item));
    }
    return convertedArray;
  } else if (!!obj && typeof obj === 'object' && obj instanceof Error) {
    return convertErrorObject(obj);
  } else if (!!obj && typeof obj === 'object') {
    const plainObject: any = {};

    Object.getOwnPropertyNames(obj).forEach((key) => {
      plainObject[key] = convertToObject(obj[key]);
    });

    return plainObject;
  }
  return obj;
}
export const stringifyError = (error: any): string => {
  const convertedObject = convertToObject(error);
  if (typeof error === 'object' && error !== null) {
    return JSON.stringify(convertedObject);
  }
  return String(error); // For cases when error is not an object
}

export const formatAsDisplayType = (value: any, displayType: TDataDisplayType | undefined) => {
  if (displayType === 'currency') {
      return formatCurrency(value);
  } else if (displayType === 'date' || displayType === 'datetime') {
      return formatDate(value, displayType);
  } else if (typeof value === 'number' || displayType === 'number') {
      return formatNumber(value);
  }

  return value;
}

export const plurify = (singularText: string, value: number): string => {
  if (!singularText) return '';

  if (singularText.endsWith('y')) return `${singularText.substring(0, singularText.length - 1)}ies`;

  return `${singularText}${value !== 1 ? 's' : ''}`;
};



export const getSafeFileName = (inputString: string): string => {
  // Remove characters that are not in the ISO-8859-1 range
  const accentMap: { [key: string]: string } = {
    'é': 'e', 'è': 'e', 'ê': 'e', 'ë': 'e',
    'á': 'a', 'à': 'a', 'â': 'a', 'ä': 'a',
    'í': 'i', 'ì': 'i', 'î': 'i', 'ï': 'i',
    'ó': 'o', 'ò': 'o', 'ô': 'o', 'ö': 'o',
    'ú': 'u', 'ù': 'u', 'û': 'u', 'ü': 'u',
    'ç': 'c', 'ñ': 'n'
  };

  // Replace accented characters using the map
  let normalized = inputString.split('').map(char => accentMap[char] || char).join('');

  // eslint-disable-next-line no-control-regex
  normalized = normalized.replace(/[^\x00-\xFF]/g, '');

  return normalized;
}

export const capitaliseString = (inputString: string): string => {
  if (!inputString) {
    return '';
  }

  return inputString.trim().split(' ')
    .filter(x => !!x.trim())
    .map(x => x.length === 1 ? x.toUpperCase() : `${x.substring(0, 1).toUpperCase()}${x.substring(1)}`)
    .join(' ');
}

export const formatCompetitorName = (competitor?: ICompetitorProfile): string => {
  if (!competitor) {
    return '';
  }

  return capitaliseString([competitor.name ?? '', competitor.surname ?? '']
    .filter(x => !!x)
    .join(' '));
}

export const formatCompetitorAndPartners = (mainCompetitor: ICompetitorProfile, partners: IPublishedCompetitionCompetitorSectionPartnerExpanded[]): string => {
  if (!mainCompetitor) {
    return '';
  }

  return [
    formatCompetitorName(mainCompetitor),
    ...((partners ?? []).map(x => x.competitorProfile ? formatCompetitorName(x.competitorProfile) : capitaliseString(`${x.name} ${x.surname}`)))
  ].join(' • ');
}

export const formatRanking = (ranking: number): string => {
  const suffixes = ["th", "st", "nd", "rd"];
  const remainder = ranking % 100;

  return ranking + (suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0]);
}