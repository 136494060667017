import React, { FunctionComponent } from 'react';

import { CircularProgress, Box, Typography } from '@mui/material';
import { TColor } from '@cw/models/shared';

const DEFAULT_SIZE = 40;

interface IProgressSpinnerValue {
    value: number;
    color: TColor;
}
interface IProgressSpinnerProps {
    variant?: 'determinate' | 'indeterminate';
    value?: number | IProgressSpinnerValue[];
    progressLabelOverride?: React.ReactNode;
    showProgressLabel?: boolean;
    progressLabelSize?: 'small' | 'normal';
    size?: number;
    color?: TColor;
}
export const ProgressSpinner: FunctionComponent<IProgressSpinnerProps> = (props: IProgressSpinnerProps) => {

    const {
        variant,
        value,
        progressLabelOverride,
        showProgressLabel,
        progressLabelSize,
        size = DEFAULT_SIZE,
        color
    } = props;

    const spinners = () => {
        if (Array.isArray(value)) {
            const orderedItems = value.slice().sort((a, b) => a < b ? -1 : b < a ? 1 : 0);
            return orderedItems.map((item, index) => (
                <CircularProgress
                    key={index}
                    size={showProgressLabel && !size ? DEFAULT_SIZE : size}
                    variant={variant}
                    value={Math.min(100, item.value)}
                    color={item.color}
                    sx={{
                        position: 'absolute',
                        left: 0,
                        zIndex: index + 2
                    }} />
            ));
        } else {
            return (
                <CircularProgress
                    size={showProgressLabel && !size ? DEFAULT_SIZE : size}
                    variant={variant}
                    value={Math.min(100, value!)}
                    color={color ?? 'primary'}
                    sx={{
                        position: 'absolute',
                        left: 0,
                        zIndex: 2
                    }} />
            )
        }
    };

    const spinnerWrapper = (
        <Box sx={{
            position: 'relative',
            height: !size ? DEFAULT_SIZE : size,
            width: !size ? DEFAULT_SIZE : size
        }}>
            <CircularProgress
                size={showProgressLabel && !size ? DEFAULT_SIZE : size}
                variant='determinate'
                value={100}
                sx={{
                    color: (theme) =>
                        theme.palette.grey[300],
                    zIndex: 1
                }} />

            {spinners()}
        </Box>
    );

    const getValueLabel = () => {
        if (progressLabelOverride) {
            return progressLabelOverride;
        } else if (!Array.isArray(value)) {
            return `${Math.round(value!)}%`;
        }

        return null;
    }

    if (variant === 'determinate' && showProgressLabel) {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                {spinnerWrapper}

                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant='caption'
                        component='div'
                        sx={{
                            color: `var(--color-${color ?? 'primary'})`,
                            fontSize: progressLabelSize === 'small' ? '0.65rem' : null
                        }}
                    >
                        {getValueLabel()}
                    </Typography>
                </Box>
            </Box>
        )
    }

    return spinnerWrapper;
};