import { DATE_FORMATS } from "@cw/constants";
import { TDateFormat } from "@cw/models/shared";
import dayjs, { Dayjs, isDayjs } from "dayjs";
import { plurify } from "./stringUtils";
import { ICompetitorProfile } from "@cw/models/competitorProfile";
import { TCompetitionSectionAgeRestrictionType } from "@cw/models/competitionSection";

export const isDate = (text: string): boolean =>
  !!text && typeof text === 'string' &&
  (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(text) || /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(.[0-9]{1,}){0,1}([Z]|(\+[0-9]{2}:[0-9]{2})){0,1}$/.test(text)) &&
  dayjs(text).isValid();

export const calculateAgeString = (dob: Dayjs, atDate?: Dayjs, yearsOnly?: boolean): string => {
  const dateNow = atDate ?? dayjs();
  const totalYears = Math.floor(dateNow.diff(dob, 'years', true));
  if (yearsOnly) {
    return `${totalYears}Y`;
  }
  const totalMonths = Math.floor(dateNow.diff(dob, 'months', true)) - (totalYears * 12);
  return `${totalYears}Y ${totalMonths}M`;
}

export const calculateTroupeAgeString = (competitorProfile: ICompetitorProfile, ageRestrictionType: TCompetitionSectionAgeRestrictionType, atDate?: Dayjs): string => {
  if (!competitorProfile || (competitorProfile.participants ?? []).length < 1) {
    return '';
  }

  const dobs = competitorProfile.participants.map(x => x.dateOfBirth);
  if (ageRestrictionType === 'AverageAge') {
    return calculateAverageAgeString(dobs, atDate);
  }

  return calculateMinMaxAgeString(dobs, atDate);
}

export const calculateAverageAgeString = (dates: Dayjs[], atDate?: Dayjs): string => {
  if (!dates || dates.length < 1) {
    return '';
  }

  const dateNow = atDate ?? dayjs();

  const totalMonths = dates.map(x => dateNow.diff(x, 'months', true)).reduce((a, b) => a + b);
  const averageMonths = Math.floor(totalMonths / dates.length);

  const years = Math.floor(averageMonths / 12);

  return `Avg: ${years}Y`;
}

export const calculateMinMaxAgeString = (dates: Dayjs[], atDate?: Dayjs): string => {
  if (!dates || dates.length < 1) {
    return '';
  }

  const dateNow = atDate ?? dayjs();

  const agesInMonths = dates.map(x => dateNow.diff(x, 'months', true));

  const minAgeInMonths = Math.min(...agesInMonths);
  const maxAgeInMonths = Math.max(...agesInMonths);

  const minAgeYears = Math.floor(minAgeInMonths / 12);
  const maxAgeYears = Math.floor(maxAgeInMonths / 12);

  if (minAgeYears === maxAgeYears) {
    return `${minAgeYears}Y`;
  }

  return `Min: ${minAgeYears}Y, Max: ${maxAgeYears}Y`;
}

export const formatSecondsAsDuration = (seconds?: number): string => {
  const duration = Math.round(seconds ?? 0);
  const totalMinutes = Math.floor((duration ?? 0) / 60);
  const totalSeconds = (duration ?? 0) - (totalMinutes * 60);

  return `${totalMinutes}:${totalSeconds.toString().padStart(2, '0')}`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatDate = (value: any, outputFormat: TDateFormat): string => {
  if (!value) {
      return '-';
  }
  
  const format = outputFormat === 'datetime' ? DATE_FORMATS.FullDateTime : DATE_FORMATS.DayMonthYear;

  if (isDayjs(value)) {
      return value.format(format);
  }

  return dayjs(value).format(format);
}

export const calculateMinutesDurationString = (minutes: number, expanded?: boolean): string => {
  const totalDays = Math.floor(minutes / (60 * 24));
  const totalHours = Math.floor(minutes / 60) - (totalDays * 24);
  const totalMinutes = minutes - (totalHours * 60) - (totalDays * 24 * 60);

  let returnResult = '';
  if (totalDays > 0) {
    returnResult = `${totalDays}${expanded ? ` ${plurify('day', totalDays)}` : 'd'} `;
  }
  if (totalHours > 0) {
    returnResult = `${totalHours}${expanded ? ` ${plurify('hour', totalHours)}` : 'h'} `;
  }
  if (totalMinutes > 0) {
    returnResult += `${totalMinutes}${expanded ? ` ${plurify('minute', totalMinutes)}` : 'm'}`;
  }

  return returnResult.trim();
}

export const calculateSecondsDurationString = (seconds?: number, expanded?: boolean): string => {
  if (!seconds && seconds !== 0) {
    return '';
  }

  const totalDays = Math.floor(seconds / (60 * 60 * 24));
  const totalHours = Math.floor((seconds - (totalDays * (60 * 24))) / (60 * 60));
  const totalMinutes = Math.floor((seconds - (totalHours * (60 * 60)) - (totalDays * (60 * 60 * 24))) / 60);
  const totalSeconds = seconds - (totalMinutes * 60) - (totalHours * (60 * 60)) - (totalDays * (60 * 60 * 24));

  let returnResult = '';
  if (totalDays > 0) {
    returnResult = `${totalDays}${expanded ? ` ${plurify('day', totalDays)}` : 'd'} `;
  }
  if (totalHours > 0) {
    returnResult = `${totalHours}${expanded ? ` ${plurify('hour', totalHours)}` : 'h'} `;
  }
  if (totalMinutes > 0) {
    returnResult += `${totalMinutes}${expanded ? ` ${plurify('minute', totalMinutes)}` : 'm'} `;
  }
  if (totalSeconds > 0) {
    returnResult += `${totalSeconds}${expanded ? ` ${plurify('second', totalSeconds)}` : 's'}`;
  }

  return returnResult.trim();
}

export const formatDateRange = (start: Dayjs, end: Dayjs, includeTimes?: boolean): string => {
  if (!start || !end) {
    return '';
  }
  
  if (start.isSame(end, 'day')) {
    const formattedDate = start.format(DATE_FORMATS.DayMonthYear);
    if (includeTimes) {
      return `${start.format(DATE_FORMATS.HourMinute)} - ${end.format(DATE_FORMATS.HourMinute)}, ${formattedDate}`;
    } else {
      return formattedDate;
    }
  }
  
  let startDate = '';
  let endDate = '';
  if (start.isSame(end, 'year') && start.isSame(end, 'month')) {
    startDate = start.format(DATE_FORMATS.DayMonth);
    endDate = end.format(DATE_FORMATS.DayMonthYear);
  } else if (start.isSame(end, 'year')) {
    startDate = start.format(DATE_FORMATS.DayMonth);
    endDate = end.format(DATE_FORMATS.DayMonthYear);
  } else {
    startDate = start.format(DATE_FORMATS.DayMonthYear);
    endDate = end.format(DATE_FORMATS.DayMonthYear);
  }

  if (includeTimes) {
    return `${start.format(DATE_FORMATS.HourMinute)}, ${startDate} - ${end.format(DATE_FORMATS.HourMinute)}, ${endDate}`;
  }
  return `${startDate} - ${endDate}`;
}