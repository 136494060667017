/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { InputAdornment, TextField, TextFieldProps, Typography } from '@mui/material';

import { useFormContext } from './Form.Context';
import { camelCaseToUserText } from '@cw/utils';

export type TFormTextInputType = 'text' | 'number' | 'email' | 'tel' | 'password' | 'color' | 'url';

interface IFormTextInputProps<T> extends Omit<TextFieldProps, 'label' | 'type' | 'name' | 'disabled' | 'value' | 'onChange' | 'InputProps' | 'error' | 'onPaste'> {
    name: keyof T;
    label?: string;
    type?: TFormTextInputType;
    disabled?: boolean;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    maxLength?: number;
    min?: number;
    max?: number;
    helperText?: string;
    doNotShowRequiredAsterisk?: boolean;
    doNotShrinkLabel?: boolean;
    labelShrinked?: boolean;
    textCenter?: boolean;
    upperCase?: boolean;
    readOnly?: boolean;
    onPaste?: (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}
export function FormTextInput<T>(props: IFormTextInputProps<T>) {
    const {
        label,
        type,
        name,
        disabled,
        startIcon,
        endIcon,
        maxLength,
        min,
        max,
        helperText,
        doNotShowRequiredAsterisk,
        doNotShrinkLabel,
        labelShrinked,
        textCenter,
        upperCase,
        readOnly,
        onPaste,
        ...baseInputProps
    } = props;

    const {
        formData,
        handleFormChange,
        disabled: formDisabled,
        formErrors,
        customFormErrors,
        submitAttempted
    } = useFormContext<T>();

    const [hasFocus, setHasFocus] = useState(false);
    const [bufferValue, setBufferValue] = useState(formData[name]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setBufferValue(newValue as any);
        if (type === 'number') {
            let parsedValue : number | null = parseFloat(newValue);
            if (isNaN(parsedValue))
                parsedValue = null;

            handleFormChange(name, parsedValue);
            return;
        }
        
        handleFormChange(name, newValue);
    };

    useEffect(() => {
        if (formData[name] !== bufferValue) {
            setBufferValue(formData[name]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    return (
        <TextField
            {...baseInputProps}
            label={label ?? camelCaseToUserText(String(name))}
            name={String(name)}
            type={type ?? 'text'}
            value={bufferValue}
            onChange={handleInputChange}
            onFocus={() => setHasFocus(true)}
            onBlur={(e) => {
                setHasFocus(false);
                if (baseInputProps.onBlur) {
                    baseInputProps.onBlur(e);
                }
            }}
            disabled={disabled || formDisabled}
            error={submitAttempted && (formErrors[name] || !!customFormErrors[name])}
            helperText={submitAttempted && !!customFormErrors[name] ? (
                <Typography variant='caption' sx={{ color: 'var(--color-error) !important' }}>{customFormErrors[name]}</Typography>
            ) : helperText}
            slotProps={{
                inputLabel: {
                    shrink: (!doNotShrinkLabel && ((!!bufferValue || bufferValue === '0' || bufferValue === 0) || hasFocus || !!startIcon)) || Boolean(labelShrinked),
                    required: doNotShowRequiredAsterisk ? false : undefined,
                    disabled: true
                },
                input: {
                    startAdornment: !startIcon ? null : (
                        <InputAdornment position='start'>
                            {startIcon}
                        </InputAdornment>
                    ),
                    endAdornment: !endIcon ? null : (
                        <InputAdornment position='end'>
                            {endIcon}
                        </InputAdornment>
                    ),
                    readOnly,
                    inputProps: {
                        min: min,
                        max: max,
                        maxLength: maxLength,
                        onPaste: onPaste,
                        step: 'any',
                        style: {
                            textAlign: textCenter ? 'center' : 'left',
                            textTransform: upperCase ? 'uppercase' : undefined
                        }
                    }
                }
            }}
        />
    )
};