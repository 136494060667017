import { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLocalDb, useRouteData } from '@cw/hooks';
import { ROUTES } from '@cw/constants';
import { TOnTheDayLinkType } from '@cw/models/onTheDay';
import { IOnTheDayUserAuthDetails, LocalDbNames, LocalDbStores } from '@cw/models/localDb';
import { formatRoute } from '@cw/utils';

interface IOnTheDayAuthGuardProps {
    linkType: TOnTheDayLinkType
    children: React.ReactNode;
}
export const OnTheDayAuthGuard: FunctionComponent<IOnTheDayAuthGuardProps> = (props: IOnTheDayAuthGuardProps) => {
    const { linkType, children } = props;

    const navigate = useNavigate();
    const { linkId, initialized: routeDataInitialized } = useRouteData<{ linkId: string }>();
    const { getItem, isLocalDbInitialized } = useLocalDb(LocalDbNames.OnTheDay, [
        LocalDbStores.OnTheDayAuthDetails,
    ]);

    useEffect(() => {
        if (!linkId || !isLocalDbInitialized || !linkType) {
            return;
        }

        getItem<IOnTheDayUserAuthDetails>(LocalDbStores.OnTheDayAuthDetails, linkId).then((authDetails) => {
            if (!authDetails || authDetails.linkType !== linkType) {
                navigate(formatRoute(ROUTES.OnTheDayLogin, { linkId }), {
                    replace: true
                });
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linkId, isLocalDbInitialized, linkType]);

    if (!isLocalDbInitialized || !routeDataInitialized) {
        return null;
    }

    return (
        <>
            {children}
        </>
    )
};