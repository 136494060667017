const numberFormatter = new Intl.NumberFormat('en-NZ');

const nzdCurrencyFormatter = new Intl.NumberFormat('en-NZ', {
  style: 'currency',
  currency: 'NZD'
});

const percentageFormatter = new Intl.NumberFormat('en-NZ', {
  style: 'percent',
  minimumFractionDigits: 0, // Adjust as needed
  maximumFractionDigits: 0, // Adjust as needed
});

export const formatCurrency = (amount: number): string => {
  return nzdCurrencyFormatter.format(amount ?? 0);
}

export const formatNumber = (amount: number): string => 
  numberFormatter.format(amount);

export const formatPercentage = (amount: number): string => 
  percentageFormatter.format(amount);

export const roundCurrency = (amount: number): number => Number(amount.toFixed(2));