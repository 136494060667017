/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import isEqual from 'fast-deep-equal/react';

import { FormContext } from './Form.Context';

export interface IFormProps<T> {
  formData: T;
  formDataChanged?: (formData: T, changedKey: keyof T | null) => void;
  onSubmit?: (formData: T) => void;
  children?: React.ReactNode;
  onSubmitValidationError?: (firstInvalidItemName: keyof T) => void;
  disabled?: boolean;
  customErrors?: Partial<Record<keyof T, string>>;
  resetKey?: string;
}
export function Form<T>(props: IFormProps<T>) {
  const {
    children,
    onSubmit,
    formData,
    disabled,
    formDataChanged,
    onSubmitValidationError,
    customErrors,
    resetKey
  } = props;

  const htmlFormRef = useRef<HTMLFormElement>(null);
  const checkErrorsDebounce = useRef<NodeJS.Timeout | null>(null);

  const [form, setForm] = useState<T>(formData);

  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [formErrors, setFormErrors] = useState<Partial<Record<keyof T, boolean>>>({});

  const updateFormErrors = useCallback(
    (forceCheck: boolean): Partial<Record<keyof T, boolean>> => {
      const newFormErrors: Partial<Record<keyof T, boolean>> = {};
      if (
        (submitAttempted || forceCheck) &&
        htmlFormRef &&
        htmlFormRef.current
      ) {
        const invalidItems = Array.from(
          htmlFormRef.current.querySelectorAll(':invalid')
        ).map((x) => x.getAttribute('name') ?? '');

        invalidItems.forEach((x) => {
          newFormErrors[x as keyof T] = true;
        });
      }

      if (!isEqual(formErrors, newFormErrors)) {
        setFormErrors(newFormErrors);
      }
      return newFormErrors;
    },
    [submitAttempted, formErrors]
  );

  const handleFormChange = useCallback((name: keyof T, value: any) => {
    setForm((fd) => {
      const updatedForm = { ...fd, [name]: value };
      if (formDataChanged) {
        formDataChanged(updatedForm, name);
      }
      return updatedForm;
    });
  }, [formDataChanged]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const latestFormErrors = updateFormErrors(true);
    if (!submitAttempted) {
      setSubmitAttempted(true);
    }

    if (Object.keys(latestFormErrors).length > 0 || Object.keys(customErrors ?? {}).length > 0) {
      const firstItemErrorKey = Object.keys(latestFormErrors)[0] ?? Object.keys(customErrors ?? {})[0];
      setTimeout(() => {
        const inputEl = htmlFormRef.current?.querySelectorAll(
          `[name="${firstItemErrorKey}"]`
        )[0] as HTMLElement;
        if (inputEl && inputEl.focus) {
          inputEl.focus();
        }
      }, 200);
      if (onSubmitValidationError) {
        onSubmitValidationError(firstItemErrorKey as keyof T);
      }
      return;
    }

    if (onSubmit) {
      onSubmit(form);
    }
  };

  useEffect(() => {
    if (!isEqual(form, formData)) {
      setForm(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    if (checkErrorsDebounce.current) {
      clearTimeout(checkErrorsDebounce.current);
    }

    checkErrorsDebounce.current = setTimeout(() => {
      updateFormErrors(false);
      checkErrorsDebounce.current = null;
    }, 250);
  }, [form, updateFormErrors]);

  useEffect(() => {
    setSubmitAttempted(false);
    htmlFormRef.current?.reset();
  }, [resetKey]);

  return (
    <form
      className='cw-form'
      onSubmit={handleSubmit}
      ref={htmlFormRef}
      noValidate={true}
      autoComplete='off'
      autoCapitalize='on'
      style={{
        width: 'inherit'
      }}
    >
      <FormContext.Provider
        value={{
          formData: form,
          disabled: disabled ?? false,
          handleFormChange: handleFormChange as any,
          formErrors,
          customFormErrors: customErrors ?? {},
          submitAttempted
        }}
      >
        {children}
      </FormContext.Provider>
    </form>
  );
}
