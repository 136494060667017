import { FunctionComponent, JSX } from 'react';

import { Snackbar as MuiSnackbar, Alert } from '@mui/material';

export type TSnackbarSeverity = 'success' | 'info' | 'warning' | 'error' | 'none';
interface ISnackbarProps {
    message: string;
    onClose: () => void; 
    severity?: TSnackbarSeverity;
}
export const Snackbar: FunctionComponent<ISnackbarProps> = (props: ISnackbarProps) => {

    const isOpen = Boolean(props.message);

    const getInnerMessage = (): JSX.Element | undefined => {
        if (!props.severity || props.severity === 'none') {
            return undefined;
        }

        return (
            <Alert severity={props.severity}>
                {props.message}
            </Alert>
        )
    };

    return (
        <MuiSnackbar
            open={isOpen}
            onClose={props.onClose}
            message={props.message}
        >
            {getInnerMessage()}
        </MuiSnackbar>
    );
};