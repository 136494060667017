import React from 'react';
import {
  AuthContext,
  AppSettingsContext,
  HttpClientContext,
  SnackbarContext,
  ConfirmContext,
  FirebaseContext,
  StripeContext,
  ClockContext,
  ScreenSizeContext
} from '@cw/contexts';

export { useBinaryToggle } from './useBinaryToggle';
export { useRouteData } from './useRouteData';
export { useLocalDb } from './useLocalDb';
export type { IDbRowChangedEvent } from './useLocalDb';
export { useWakeLock } from './useWakeLock';
export { useFileUploader } from './useFileUploader';
export { useAudioRecorder } from './useAudioRecorder';
export type { IAudioRecorderRef } from './useAudioRecorder';

export const useAuth = () => React.useContext(AuthContext);
export const useAppSettings = () => React.useContext(AppSettingsContext);
export const useHttpClient = () => React.useContext(HttpClientContext);
export const useSnackbar = () => React.useContext(SnackbarContext);
export const useConfirm = () => React.useContext(ConfirmContext);
export const useFirebase = () => React.useContext(FirebaseContext);
export const useStripe = () => React.useContext(StripeContext);
export const useClock = () => React.useContext(ClockContext);
export const useScreenSize = () => React.useContext(ScreenSizeContext);