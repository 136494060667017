export const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again later.';

export const QUERY_CLIENT_STALE_TIME = 1000 * 60 * 30; // 30 Minutes

export enum ROUTES {
  Root =  '/',
  Login = '/login',

  Dashboard = '/dashboard',
  Competitions = '/dashboard/competitions',
  ManageCompetition = '/dashboard/competitions/:id',
  EditAccount = '/dashboard/account',
  CompWorldGuidesPage = '/dashboard/comp-world-guides',

  OnTheDayLogin = '/otd/:linkId',
  OnTheDayLiveMusicPlayer = '/otd/:linkId/live-music-player',
  OnTheDayStageLineupMonitor = '/otd/:linkId/stage-lineup-monitor',
  OnTheDayAdjudicator = '/otd/:linkId/adjudicator',

  WaitlistInvite = '/waitlist-invite',
};
export const getRouteName = (route: string, parentRoute?: string): string => {
  let routeName = route;
  if (parentRoute) {
    routeName = routeName.replace(parentRoute, '');
  }
  if (routeName.startsWith('/')) {
    routeName = routeName.substring(1);
  }
  if (routeName.endsWith('/')) {
    routeName = routeName.substring(0, routeName.length - 1);
  }
  return routeName;
}

export const compareToRoutePattern = (routePattern: string, pathName: string): boolean => {
  const regexPattern = new RegExp("^" + routePattern.replace(/:[A-Za-z]+/g, "([^/]+)") + "$");
  return regexPattern.test(pathName);
}

export enum MANAGE_COMPETITION_TABS {
  EditCompetition = 'edit',
  Schedule = 'schedule',
  Competitors = 'competitors',
  Sales = 'sales',
  LivePlayer = 'live-player',
  Results = 'results',
  Publish = 'publish'
};

export const DATE_FORMATS = {
  /**
   * `D MMM YYYY hh:mm a`
   */
  FullDateTime: 'D MMM YYYY hh:mm a',

  /**
   * `D MMM YYYY`
   */
  DayMonthYear: 'D MMM YYYY',

  /**
   * `D MMM YYYY`
   */
  DayMonthYearWithFullDay: 'dddd, D MMM YYYY',

  /**
   * `D MMM`
   */
  DayMonth: 'D MMM',

  /**
   * `D`
   */
  Day: 'D',

  /**
   * `ddd`
   */
  DayOfWeek: 'ddd',

  /**
   * `hh a`
   */
  Hour: 'hh a',

  /**
   * `hh:mm a`
   */
  HourMinute: 'hh:mm a',

  /**
   * `hh:mm:ss a`
   */
  HourMinuteSecond: 'hh:mm:ss a',

  /**
   * `D MMM hh:mm a`
   */
  DayMonthHour: 'D MMM hh:mm a'
};

export type AUDIO_TYPE = 'MP3' | 'M4A' | 'WAV' | 'AAC' | 'FLAC' | 'AIFF' | 'WMA';
export const AUDIO_EXTENSIONS: Record<AUDIO_TYPE, string> = {
  MP3: 'mp3',
  M4A: 'm4a',
  WAV: 'wav',
  AAC: 'aac',
  FLAC: 'flac',
  AIFF: 'aiff',
  WMA: 'wma'
}
export const AUDIO_MIME_TYPES: Record<AUDIO_TYPE, string[]> = {
  MP3: ['audio/mpeg'],
  M4A: ['audio/x-m4a', 'audio/mp4'],
  WAV: ['audio/wav', 'audio/wave', 'audio/vnd.wave', 'audio/x-wav'],
  AAC: ['audio/aac'],
  FLAC: ['audio/flac'],
  AIFF: ['audio/aiff'],
  WMA: ['audio/x-ms-wma']
}
export const ALLOWED_AUDIO_TYPES: AUDIO_TYPE[] = [
  'MP3', 'M4A', 'WAV', 'AAC', 'FLAC'
];
export const ALLOWED_AUDIO_MIME_TYPES: string[] = ALLOWED_AUDIO_TYPES.flatMap(x => AUDIO_MIME_TYPES[x]);

export enum SCREEN_SIZE {
  XS = 0,
  SM = 1,
  MD = 2,
  LG = 3,
  XL = 4
}