import { FunctionComponent } from 'react';

import { CardContent as MuiCardContent, CardContentProps as MuiCardContentProps } from '@mui/material';
import { useScreenSize } from '@cw/hooks';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface ICardContentProps extends MuiCardContentProps {}
export const CardContent: FunctionComponent<ICardContentProps> = (props: ICardContentProps) => {

    const { isMobile } = useScreenSize();

    return (
        <MuiCardContent {...props} sx={{
            padding: isMobile ? '0px' : undefined,
            ...(props.sx ?? {})
        }} />
    );
}