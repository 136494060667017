type TClassName = string | Record<string, boolean | undefined> | undefined;

/**
 * Combine class names
 * @param classNames Class names to combine
 * @returns string
 */
export const classNames = (...classNames: TClassName[]): string => {
  return classNames
    .filter(x => !!x)
    .flatMap(x => typeof x === 'string' ? x : Object.keys(x!).filter(key => Boolean(x![key])))
    .join(' ')
};

type TComponentClassNameBuilder = (...classNames: string[]) => string;
export const componentClassName = (componentName: string): TComponentClassNameBuilder => {
  const safeClassName = `cw-${componentName}`;
  return (...classNames: string[]) => {
    if (classNames.length < 1) return safeClassName;
    
    return `${safeClassName}--${classNames.join('--')}`;
  }
}