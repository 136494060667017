/* eslint-disable @typescript-eslint/no-explicit-any */
import { QUERY_CLIENT_STALE_TIME } from "@cw/constants";
import React, { JSX } from "react";

export type TColor =
  | "primary"
  | "secondary"
  | "tertiary"
  | "error"
  | "success"
  | "warning"
  | "info"
  | "light"
  | "dark";
export type THttpMethod = "GET" | "POST" | "PUT" | "DELETE";

export type TDateFormat = "datetime" | "date";
export type TDataDisplayType = TDateFormat | "currency" | "number";

export enum PAYMENT_METHOD {
  Card = 'Card',
  Afterpay = 'Afterpay'
}

export enum QueryKeys {
  AppSettings = "AppSettingsQueryKey",
  Self = "SelfQueryKey",
  Competitions = "CompetitionsQueryKey",
  CompetitionSalesSummaries = "CompetitionSalesSummariesQueryKey",
  CompetitionSalesPayments = "CompetitionSalesPaymentsQueryKey",
  CompetitionSections = "CompetitionSectionsQueryKey",
  CompetitionSchedule = "CompetitionScheduleQueryKey",
  CompetitionCompetitors = "CompetitionCompetitorsQueryKey",
  CompetitionReportSheets = "CompetitionReportSheetsQueryKey",
  CompetitionSpecialAwards = "CompetitionSpecialAwardsQueryKey",
  HostProfiles = "HostProfilesQueryKey",
  HostProfileEmbeddedStripeSessions = "HostProfileEmbeddedStripeSessionsQueryKey",
  OnTheDayLinks = "OnTheDayLinksQueryKey",
  OnTheDayInitData = "OnTheDayInitDataQueryKey",
  Schools = "SchoolsQueryKey",
  CompetitionWaitList = "CompetitionWaitListQueryKey",
  CompetitionWaitListInviteData = "CompetitionWaitListInviteDataQueryKey"
}

export interface IQuerySettings<TResponse = any, TInput = any> {
  enabled?: boolean;
  onSuccess?: (response: TResponse, input?: TInput) => void;
  onError?: (error: HttpClientError, input?: TInput) => void;
  onSettled?: () => void;
  staleTime?: number;
  refetchInterval?: number | false;
  keepPreviousData?: boolean;
  retry?: boolean | number | ((failureCount: number, error: any) => boolean);
  retryDelay?: number;

  autoShowErrorMessages?: boolean;
  autoLogOutOnAuthError?: boolean;
}
export function getQuerySettings<TResponse = any, TInput = any>(
  settings?: IQuerySettings<TResponse, TInput>
): IQuerySettings<TResponse, TInput> {
  return {
    enabled: true,
    staleTime: QUERY_CLIENT_STALE_TIME,
    refetchInterval: false,
    autoShowErrorMessages: true,
    autoLogOutOnAuthError: true,
    keepPreviousData: false,
    retryDelay: 1000,
    onError: (error) => {
      // Swallow error by default. Don't want each non 200 to raise alarms in NR
      console.warn(error);
    },
    ...(settings ?? {}),
    retry: !!settings?.retry && typeof settings.retry === 'number' ? (failureCount: number) => { return failureCount <= (settings.retry as number) } : (settings?.retry ?? false),
  };
}

export interface IReducerAction<T> {
  type: T;
  payload?: any;
}

export enum FeatureFlag {
  DiscountCodesEnabled = 'DiscountCodesEnabled'
}

interface IFirebaseAppSettings {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  measurementId: string;
}
interface IStripePaymentMethodServiceFees {
  serviceFeePercentage: number;
  serviceFeeFixed: number;
}
export interface IAppSettings {
  apiBaseUrl: string;
  blobBaseUrl: string;
  websocketApiBaseDomain: string;
  mobileAppWebsiteBaseDomain: string;
  deepLinkBaseDomain: string;
  internalAdminWebsiteBaseDomain: string;
  isInternalAdminMode: boolean;
  environment?: "local" | "dev" | "prod";
  buildVersion?: string;
  googleApiKey: string;
  stripe: {
    publishKey: string;
    paymentMethodServiceFees: Record<PAYMENT_METHOD, IStripePaymentMethodServiceFees>;
  };
  featureFlags: Partial<Record<FeatureFlag, boolean>>;
  firebase: IFirebaseAppSettings;
  firebaseInternal: IFirebaseAppSettings;
  mobileAppSchemes: {
    android: string;
    ios: string;
    iosAppId: string;
  }
}

export interface IMenuItem {
  value?: string;
  icon?: JSX.Element;
  text?: string;
  content?: React.ReactNode;
  isDivider?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  routerLink?: string;
  data?: any;
  color?: TColor;
}

export interface IDefaultResponseMessage {
  message?: string;
  boolean?: boolean;
}

export interface IAddress {
  fullAddress: string;
  street: string;
  suburb: string;
  city: string;
  region: string;
  postalCode: string;
  country: string;
  countryCode: string;
  addressId: string;
  location?: IGeoPoint | null;
}

export interface IGeoPoint {
  latitude: number;
  longitude: number;
}

export interface ISocialLinks {
  website?: string;
  facebook?: string;
  instagram?: string;
  youtube?: string;
  linkedIn?: string;
}

export interface IMusicFile {
  key: string;
  uploadName: string;
  sizeInBytes: number;
  durationSeconds: number;
}

export interface IFileUploadRequest {
  fileBase64: string;
  contentType: string;
  fileName: string;
}

export interface IPreSignedBlobUrl {
  key: string;
  fileName: string;
  signedUrl: string;
  metadata: Record<string, string>;
}

export interface IDragOptions {
  draggable?: boolean;
  disabled?: boolean;
  uniqueId: string;
  index: number;
}

interface IHttpErrorResponse {
  requestUrl: string;
  requestMethod: THttpMethod;
  statusCode: number;
  errorMessages: string[];
  rawErrors?: any;
}
export class HttpClientError extends Error {
  public statusCode: number;
  public firstErrorMessage: string;
  public errorMessages: string[];

  public errorResponse: IHttpErrorResponse;

  constructor(errorResponse: IHttpErrorResponse) {
    super(
      `[${errorResponse.statusCode}] error while performing [${errorResponse.requestMethod} ${errorResponse.requestUrl}]`,
      {
        cause: errorResponse.rawErrors,
      }
    );

    Object.setPrototypeOf(this, HttpClientError.prototype);

    this.errorResponse = errorResponse;
    this.statusCode = errorResponse.statusCode;
    this.firstErrorMessage = errorResponse.errorMessages[0];
    this.errorMessages = errorResponse.errorMessages;
  }
}
