import { IMusicFile } from "./shared";

export type TCompetitionSectionAgeRestrictionType = 'MinMaxAge' | 'AverageAge';
export type TCompetitionSectionPrerequisiteType = 'NoPrerequisite' | 'OtherSectionsEntered';

export enum COMPETITION_SECTION_TYPE {
  Solo = 'Solo',
  Duo = 'Duo',
  Trio = 'Trio',
  Troupe = 'Troupe',
  DuoTrio = 'DuoTrio',
  Workshop = 'Workshop'
};

export enum COMPETITION_SECTION_MUSIC_UPLOAD_BY_TYPE {
  Competitor = 'Competitor',
  Host = 'Host'
}

export interface ICompetitionSection {
  id: string;
  order: number;
  name: string;
  sectionNumber: string;
  minAge: number;
  maxAge: number;
  ageRestrictionType: TCompetitionSectionAgeRestrictionType;
  maxEntries?: number;
  averagePerformanceDurationSeconds: number;
  averageTimeBetweenPerformancesSeconds: number;
  sectionDurationSecondsOverride?: number;
  entryFee: number;
  sectionType: COMPETITION_SECTION_TYPE;
  requireTitleOfDance: boolean;
  prerequisites: ICompetitionSectionPrerequisites;
  impromptuMusicFile?: IMusicFile;
  musicUploadBy: COMPETITION_SECTION_MUSIC_UPLOAD_BY_TYPE;
}

export interface ICompetitionSectionPrerequisites {
  prerequisiteType: TCompetitionSectionPrerequisiteType;
  otherSectionsToEnter: string[];
  otherSectionsToEnterThatIsCompulsory: string[];
  amountOfOtherSectionsToEnter: number;
}

export interface ICreateCompetitionSectionRequest {
  name: string;
  sectionNumber: string;
  minAge: number;
  maxAge: number;
  ageRestrictionType: TCompetitionSectionAgeRestrictionType;
  maxEntries: number | null;
  averagePerformanceDurationSeconds: number;
  averageTimeBetweenPerformancesSeconds: number;
  sectionDurationSecondsOverride: number | null;
  entryFee: number;
  sectionType: COMPETITION_SECTION_TYPE;
  requireTitleOfDance: boolean;
  prerequisites: ICompetitionSectionPrerequisites;
  musicUploadBy: COMPETITION_SECTION_MUSIC_UPLOAD_BY_TYPE;
}

export interface IUpdateCompetitionSectionRequest {
  name: string;
  sectionNumber: string;
  minAge: number;
  maxAge: number;
  ageRestrictionType: TCompetitionSectionAgeRestrictionType;
  maxEntries: number;
  averagePerformanceDurationSeconds: number;
  averageTimeBetweenPerformancesSeconds: number;
  sectionDurationSecondsOverride: number | null;
  entryFee: number;
  sectionType: COMPETITION_SECTION_TYPE;
  requireTitleOfDance: boolean;
  prerequisites: ICompetitionSectionPrerequisites;
  musicUploadBy: COMPETITION_SECTION_MUSIC_UPLOAD_BY_TYPE;
}

export interface IUpdateCompetitionSectionOrderRequest {
  order: number;
}

export interface ICreateCompetitionSectionImpromptuMusicUploadLinkRequest {
  fileName: string;
  contentType: string;
}

export interface IUpdateCompetitionSectionImpromptuMusicRequest {
  songKey: string;
  songDurationSeconds: number;
  songSizeInBytes: number;
}