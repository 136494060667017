import { FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '@cw/hooks';
import { ROUTES } from '@cw/constants';

interface IAuthGuardProps {
    children: React.ReactNode;
}
export const AuthGuard: FunctionComponent<IAuthGuardProps> = (props: IAuthGuardProps) => {
    const { children } = props;

    const { isAuthenticated, isInitialized } = useAuth();

    if (!isInitialized) {
        return null;
    } else if (!isAuthenticated) {
        return <Navigate to={ROUTES.Root} />
    }

    return (
        <>
            {children}
        </>
    )
};